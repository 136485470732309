import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import AdditionalSupportInformation from "../../sections/support/additionalSupportInformation";
import svgHeroShape from "../../assets/image/svg/hero-shape-svg.svg";
import { graphql } from "gatsby";
import { getProperty } from "../../utils/helperFn";
import { Link } from "gatsby"
import { Box, Button, Title, MainTitle, Section } from "../../components/Core";
import ReactMarkdown from 'react-markdown'
import Seo from "../../components/Seo/Seo";
import "../support/index.scss"
const gfm = require('remark-gfm')

const ShapeTopLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
`;

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  margin-top: 160px;
  min-height: 100vh;
`;

const BigTitle = styled(MainTitle)`{
  font-size: 2.8em;
}`

const StrapiSupportArticle = (props) => {

    const data = props.data ? getProperty(props, 'data') : {}
    const articleData = getProperty(data, 'strapiLegalArticles')

    return (
        <div>
            <Seo page={articleData.article_slug} />

            <Section>
                <Container>
                    <Row>
                        <Col sm={12} md={10} className="mx-auto">
                            <div className="text-center text-md-left">
                                <h5 className="text-center">
                                    <Link to="/" className="mr-1">
                                        Home
                                    </Link>
                                    <span className="mr-1">\</span>
                                    <Link to="/legal/" className="mr-1">Legal</Link>
                                    <span className="mr-1">\</span>
                                    <Link to={"/legal/" + getProperty(articleData, 'article_slug')}>{articleData.title}</Link>
                                </h5>

                                <h1 className="mt-5 pb-3 text-center">
                                    {articleData.title}
                                </h1>
                                <div className="p t-0">
                                    <ReactMarkdown plugins={[gfm]} className="supportArticleContent" source={articleData.content} />
                                </div>

                            </div>
                        </Col>
                        <Col sm={12} md={10} className="mx-auto text-center mt-5">
                            <Link to="/legal/">
                                <Button className="blue-bg">Back to Legal Articles</Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </div>
    )
}

export const data = graphql`
    query getLegalArticle($article_slug: String) {
      strapiLegalArticles(article_slug: {eq: $article_slug}) {
        content
        title
        article_slug
      }
    }`



export default StrapiSupportArticle